import React from 'react'
import Layout from '../components/layout'

import bg from '../images/eat_fit_abq_menus.png'

const Contact = () => (
  <Layout>
    <img
      style={{ position: 'absolute', right: 10, top: 10 }}
      src={bg}
      alt="background"
    />
    <div
      className="header"
      style={{
        position: 'absolute',
        left: 250,
        top: 40,
      }}
    >
      How It Works
    </div>
    <div
      style={{
        width: 500,
        fontFamily: 'Verdana, Geneva, sans-serif',
        fontSize: 14,
        position: 'absolute',
        top: 83,
        left: 250,
      }}
    >
      <p>
        You can choose from the menu that I change every week or I can send a
        variety. 8 meal minimum per week. Orders need to be in by Thursday 9am.
        <br />
        New menu will be posted on my website&nbsp;
        <a href="https://eatfitdelivery.com" className="greentext">
          EatFitDelivery
        </a>
        &nbsp;and I will send an email Monday evenings.
        <br />
        <br />
        I offer meals that are:
        <br />• Paleo • Clean • Count Macros • Gluten Free
        <br />• Low Sugar • Low Sodium • Keto
        <br />
        <br />
        An address to deliver to on Saturday or Sunday and Wednesday's or
        Thursday (depending which part of town). We will text with an ETA. I
        continue delivering until told otherwise. Orders need to be in by
        Wednesday evenings 5 pm.
        <br />
        <br />
        Please leave out a cooler with ice packs if you will not be home.
        <br />
        Please email me your order with:
        <br />
        • Address
        <br />
        • Phone number.
        <br />
        • I will send invoice through email and you can pay online
        <br />
        <br />
        Smaller portion meals $11 plus tax (4 oz of protein plus carb and
        veggie)
        <br />
        Larger portion meals $12 plus tax (5.5 oz of protein plus veggie and
        carbs)
        <br />
        <br />
        Please email me all the above information at&nbsp;
        <a href="mailto:eatfitabq@gmail.com" className="greentext">
          eatfitabq@gmail.com
        </a>
        .
        <br />
        <br />
        Thank you, Nohime Dennisson
      </p>
    </div>
    <div
      style={{
        position: 'absolute',
        top: 752,
        left: 255,
        fontSize: 12,
        fontFamily: 'Verdana, Geneva, sans-serif',
      }}
    >
      <p>
        <b>
          * All orders and/or cancellations must be placed by 9am Thursday for
          the following week.
        </b>
      </p>
    </div>
  </Layout>
)

export default Contact
